import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'root',
        component: () => import('./components/C_Search.vue'),
    },
    {
        path: '/tokens',
        name: 'tokens',
        component: () => import('./components/C_LudiToken.vue'),
    },
    {
        path: '/objectives',
        name: 'objectives',
        component: () => import('./components/C_Objectives.vue'),
    },
    {
        path: '/objectiveslist/',
        name: 'objectiveslist',
        component: () => import('./components/C_ObjectivesCoach.vue'),
        props: true
    },
    {
        path: '/objectivesuser/:uid',
        name: 'objectivesuser',
        component: () => import('./components/C_ObjectivesCoachUser.vue'),
        props: true
    },
    {
        path: '/admin_xlm',
        name: 'admin_xlm',
        component: () => import('./components/C_AdminXLM.vue'),
    },
    {
        path: '/admin_xlm',
        name: 'admin_xlm',
        component: () => import('./components/C_AdminXLM.vue'),
    },
    {
        path: '/search',
        name: 'search',
        component: () => import('./components/C_Search.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./components/C_Login.vue'),
    },
    {
        path: '/signout',
        name: 'signout',
        component: () => import('./components/C_Logout.vue'),
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('./components/C_Profile.vue'),
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('./components/C_About.vue'),
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import('./components/C_Settings.vue'),
    },
    {
        path: '/conversations',
        name: 'conversations_base',
        component: () => import('./components/C_Conversations.vue'),
    },
    {
        path: '/conversations/:uid',
        name: 'conversations',
        component: () => import('./components/C_Conversations.vue'),
        props: true
    },
    {
        path: '/signup',
        name: 'signup',
        component: () => import('./components/C_Signup.vue'),
        children: [
            {
                path: '',
                name: 'signup1',
                component: () => import('./components/c_signup/Account.vue'),
            },
            {
                path: '/signup/questionnaire',
                name: 'signup2',
                component: () => import('./components/c_signup/Questionnaire.vue'),
            },
            {
                path: '/signup/completion',
                name: 'signup3',
                component: () => import('./components/c_signup/Completion.vue'),
            },
            {
                path: '/signup/review',
                name: 'signup4',
                component: () => import('./components/c_signup/Review.vue'),
            },
        ],
    },
    {
        path: '/signupcoach',
        name: 'signupcoach',
        component: () => import('./components/C_Signup_Coach.vue'),
        children: [
            {
                path: '',
                name: 'signup1coach',
                component: () => import('./components/c_signup_coaches/Account.vue'),
            },
            {
                path: '/signupcoach/priorities',
                name: 'signup2coach',
                component: () => import('./components/c_signup_coaches/Priorities.vue'),
            },
            {
                path: '/signupcoach/completion',
                name: 'signup3coach',
                component: () => import('./components/c_signup_coaches/Completion.vue'),
            },
            {
                path: '/signupcoach/review',
                name: 'signup4coach',
                component: () => import('./components/c_signup_coaches/Review.vue'),
            },
        ],
    },
    {
        path: '/coachprofile/:uid',
        name: 'coachprofile',
        component: () => import('./components/C_ProfileCoach.vue'),
        props: true
    },
    {
        path: '/userprofile/:uid',
        name: 'userprofile',
        component: () => import('./components/C_Profile.vue'),
        props: true
    },
    {
        path: '/editprofile',
        name: 'editprofile',
        component: () => import('./components/C_EditProfile.vue'),
    },
    {
        path: '/editphoto',
        name: 'editphoto',
        component: () => import('./components/C_EditPhoto.vue'),
    },
    {
        path: '/redoquestionnaire',
        name: 'redoquestionnaire',
        component: () => import('./components/C_RedoQuestionnaire.vue'),
    },
    {
        path: '/redopriorities',
        name: 'redopriorities',
        component: () => import('./components/C_RedoPriorities.vue'),
    },
    {
        path: '/signuptype',
        name: 'signuptype',
        component: () => import('./components/C_SelectSignupType.vue'),
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('./components/C_Contact.vue'),
    },
    {
        path: '/forgotpassword',
        name: 'forgotpassword',
        component: () => import('./components/C_ForgotPassword.vue'),

    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
