<template>
	<div class="layout-footer">
		<img alt="Logo" :src="footerImage()" height="20" class="mr-2" />
	</div>
</template>

<script>
	export default {
		name: "AppFooter",
		methods: {
			footerImage() {
				return 'images/coachera-logo.png';
			}
		},
		computed: {
			darkTheme() {
				return false;
			}
		}
	}
</script>

<style scoped>

</style>
