<template>
  <Toast />
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" isSignUp="isSignUp" />
    <div class="layout-sidebar" @click="onSidebarClick" v-if="!isSignUp">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
      <AppFooter />
    </div>

    <transition name="layout-mask">
      <div
        class="layout-mask p-component-overlay"
        v-if="mobileMenuActive"
      ></div>
    </transition>
    <!-- <v-tour name="myTour" :steps="steps" :options="myOptions"></v-tour> -->
  </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
import AppMenu from "./AppMenu.vue";
import AppFooter from "./AppFooter.vue";
import UserService from "./service/UserService";

export default {
  data() {
    return {
      // myOptions: {
      //   useKeyboardNavigation: true,
      //   highlight: true,
      //   placement: "bottom",
      //   // enableScrolling: false,
      //   labels: {
      //     buttonSkip: "Skip",
      //     buttonPrevious: "Previous",
      //     buttonNext: "Next",
      //     buttonStop: "Finish",
      //   },
      // },
      // steps: [
      //   {
      //     target: ".layout-main",
      //     content:
      //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      //   },
      //   {
      //     target: "#conversations",
      //     content: "An awesome plugin made with Vue.js!",
      //     // params: {
      //     //   placement: "top",
      //     // },
      //   },
      //   {
      //     target: "#profile",
      //     content:
      //       "Try it, you'll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.",
      //     // params: {
      //     //   placement: "top",
      //     // },
      //   },
      // ],

      layoutMode: "static",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        {
          label: "Home",
          items: [
            {
              label: "Conversations",
              icon: "pi pi-fw pi-comment",
              to: "/conversations",
              id: "conversations",
            },
            {
              label: "Search",
              icon: "pi pi-fw pi-search",
              to: "/search",
              id: "search",
            },
            {
              label: "Profile",
              icon: "pi pi-fw pi-user",
              to: "/profile",
              id: "profile",
            },
            {
              label: "Ludi Tokens",
              icon: "pi pi-fw pi-dollar",
              to: "/tokens",
              id: "tokens",
            },
          ],
        },
        {
          label: "Other",
          items: [
            {
              label: "Settings",
              icon: "pi pi-fw pi-cog",
              to: "/settings",
              id: "settings",
            },
            {
              label: "Contact",
              icon: "pi pi-fw pi-envelope",
              to: "/contact",
              id: "contact",
            },
            {
              label: "About",
              icon: "pi pi-fw pi-info-circle",
              to: "/about",
              id: "about",
            },
            {
              label: "Sign Out",
              icon: "pi pi-fw pi-sign-out",
              to: "/signout",
              id: "sign-out",
            },
          ],
        },
      ],
      iscoach: null,
    };
  },
  userService: null,
  created() {
    this.userService = new UserService();
  },
  mounted() {
    if (
      this.$route.name &&
      typeof this.$route.name !== "undefined" &&
      this.$route.name !== null
    ) {
      if (
        this.$route.name.startsWith("signup") ||
        this.$route.name.startsWith("login") ||
        this.$route.name.startsWith("forgotpassword")
      ) {
        this.staticMenuInactive = true;
        this.menuClick = true;
      }
    }
    this.getIsCoach();
    // this.$tours["myTour"].start();
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();

      if (this.$route.name.startsWith("search")) {
        this.getIsCoach();
      }

      if (
        this.$route.name.startsWith("signup") ||
        this.$route.name.startsWith("login") ||
        this.$route.name.startsWith("forgotpassword")
      ) {
        this.staticMenuInactive = true;
        this.menuClick = true;
      } else {
        this.staticMenuInactive = false;
        this.menuClick = false;
      }
    },
  },
  methods: {
    getIsCoach() {
      // TODO: Dirty solution.
      this.userService.getUser(-1).then((data) => {
        this.iscoach = data["coach"];

        var found = false;
        for (var i in this.menu[0]["items"]) {
          if (this.menu[0]["items"][i]["to"].startsWith("/redo") || this.menu[0]["items"][i]["to"].startsWith("/objectives")) {
            // found = true;
            this.menu[0]["items"].splice(i, 2);
            break;
          }
        }
        if (!found) {
          if (this.iscoach) {
            this.menu[0]["items"].push({
              label: "Objectives",
              icon: "pi pi-fw pi-check-circle",
              to: "/objectiveslist",
              id: "objectiveslist",
            });
            this.menu[0]["items"].push({
              label: "Modify Priorities",
              icon: "pi pi-fw pi-check-square",
              to: "/redopriorities",
              id: "redo_priorities",
            });
          } else {
            this.menu[0]["items"].push({
              label: "Objectives",
              icon: "pi pi-fw pi-check-circle",
              to: "/objectives",
              id: "objectives",
            });
            this.menu[0]["items"].push({
              label: "Redo Questionnaire",
              icon: "pi pi-fw pi-question",
              to: "/redoquestionnaire",
              id: "redo_questionnaire",
            });
          }
        }
      });
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
      }

      return true;
    },
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
          "layout-theme-light": this.$appState.theme.startsWith("saga"),
        },
      ];
    },
    logo() {
      return this.$appState.darkTheme
        ? "images/logo-white.svg"
        : "images/logo.svg";
    },
    isSignUp() {
      if (
        this.$route &&
        this.$route.name &&
        typeof this.$route.name !== "undefined" &&
        this.$route.name !== null
      ) {
        return (
          this.$route.name.startsWith("signup") ||
          this.$route.name.startsWith("login") ||
          this.$route.name.startsWith("forgotpassword")
        );
      }
      return false;
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    AppFooter: AppFooter,
  },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
