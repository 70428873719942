// const IP = "http://127.0.0.1:5105";
const IP = "https://api.ludi.coach";
// const IP = "http://192.168.0.115:5105";
let cors = 'cors'

import { VueCookieNext } from 'vue-cookie-next';

export default class UserService {

    getXLMAdmin() {
        return fetch(
            IP + '/xlm/get_admin',
            {
                mode: cors,
                credentials: 'include'
            }
        ).then(res => res.json());
    }

    getXLM() {
        return fetch(
            IP + '/xlm/get_tokens',
            {
                mode: cors,
                credentials: 'include'
            }
        ).then(res => res.json());
    }

    acceptXLMAdmin(id_) {
        return fetch(
            IP + '/xlm/accept/' + id_,
            {
                mode: cors,
                credentials: 'include'
            }
        ).then(res => res.json());
    }

    getMatch() {
        return fetch(
            IP + '/accounts/get_match',
            {
                mode: cors,
                credentials: 'include'
            }
        ).then(res => res.json());
    }

    getUser(uid = -1) {
        return fetch(
            IP + '/accounts/get/' + uid,
            {
                mode: cors,
                credentials: 'include'
            }
        ).then(res => res.json());
    }

    logoutUser() {
        return fetch(
            IP + '/accounts/logout',
            {
                mode: cors,
                credentials: 'include'
            }
        ).then(res => res.json());
    }

    createUser(data) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
            mode: cors,
            credentials: 'include'
        };
        return fetch(IP + "/accounts/create", requestOptions)
            .then(res => res.json());
        // .then(d => d.data);
    }

    updateUser(data) {
        // var myTimezone = new Date().toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1];
        // data["timezone"] = myTimezone;
        var offset = new Date().getTimezoneOffset();
        data["timezone"] = offset;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
            mode: cors,
            credentials: 'include'
        };
        return fetch(IP + "/accounts/update", requestOptions)
            .then(res => res.json());
        // .then(d => d.data);
    }

    loginUser(data) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
            mode: cors,
            credentials: 'include'
        };
        return fetch(IP + "/accounts/login", requestOptions)
            .then(res => res.json());
        // .then(d => d.data);
    }

    upload(fd) {
        const requestOptions = {
            method: 'POST',
            body: fd,
            mode: cors,
            credentials: 'include'
        };
        console.log(fd);
        return fetch(IP + "/accounts/upload", requestOptions)
            .then(res => res.json())
            .then(d => d.data);
    }

    saveConversation(data) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
            mode: cors,
            credentials: 'include'
        };
        return fetch(IP + "/conversations/save", requestOptions)
            .then(res => res.json());
        // .then(d => d.data);
    }

    getConversations() {
        return fetch(
            IP + '/conversations/get',
            {
                mode: cors,
                credentials: 'include'
            }
        ).then(res => res.json());
    }

    getMessages(uid) {
        return fetch(
            IP + '/messages/get/' + uid,
            {
                mode: cors,
                credentials: 'include'
            }
        ).then(res => res.json());
    }

    addMessage(uid, msg) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ "uid": uid, "msg": msg }),
            mode: cors,
            credentials: 'include'
        };
        return fetch(IP + "/messages/add", requestOptions)
            .then(res => res.json());
        // .then(d => d.data);
    }

    getNewMessages() {
        return fetch(
            IP + '/messages/getnew',
            {
                mode: cors,
                credentials: 'include'
            }
        ).then(res => res.json());
    }

    getColorsCookie() {
        return VueCookieNext.getCookie('colortheme');
    }

    getColors() {
        // var theme = this.$cookie.get("colortheme");
        var theme = VueCookieNext.getCookie('colortheme');

        if (theme == "blue") {
            return ["white", "#888ed5", "#6069c8", "#3944bc"]
        } else if (theme == "green") {
            return ["black", "#83D475", "#ABE098", "#d7f7ca"]
        } else {
            return ["white", "#3944bc", "#6069c8", "#888ed5"]
        }
    }

    setColors(value) {
        VueCookieNext.setCookie('colortheme', value);
    }

    updateRating(data) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
            mode: cors,
            credentials: 'include'
        };
        return fetch(IP + "/rating/update", requestOptions)
            .then(res => res.json());
        // .then(d => d.data);
    }

    getRating(uid) {
        return fetch(
            IP + '/rating/get/' + uid,
            {
                mode: cors,
                credentials: 'include'
            }
        ).then(res => res.json());
    }

    createContact(data) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
            mode: 'cors',
            credentials: 'include'
        };
        return fetch(IP + "/contact/create", requestOptions)
            .then(res => res.json());
        // .then(d => d.data);
    }

    forgotpassword(data) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
            mode: cors,
            credentials: 'include'
        };
        return fetch(IP + "/accounts/forgotpassword", requestOptions)
            .then(res => res.json());
    }

    resetpasswordcode(data) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
            mode: cors,
            credentials: 'include'
        };
        return fetch(IP + "/accounts/resetpasswordcode", requestOptions)
            .then(res => res.json());
    }

    resetpassword(data) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
            mode: cors,
            credentials: 'include'
        };
        return fetch(IP + "/accounts/resetpassword", requestOptions)
            .then(res => res.json());
    }

    getTutorialDone() {
        return VueCookieNext.getCookie('tutorialdone');
    }

    setTutorialDone(value) {
        VueCookieNext.setCookie('tutorialdone', value);
    }

    getObjectives(uid = -1) {
        return fetch(
            IP + '/objectives/get/' + uid,
            {
                mode: cors,
                credentials: 'include'
            }
        ).then(res => res.json());
    }

    createObjective(data) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
            mode: cors,
            credentials: 'include'
        };
        return fetch(IP + "/objectives/create", requestOptions)
            .then(res => res.json());
    }

    updateObjective(data) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
            mode: cors,
            credentials: 'include'
        };
        return fetch(IP + "/objectives/update", requestOptions)
            .then(res => res.json());
    }

    delObjective(id_) {
        return fetch(
            IP + '/objectives/del/' + id_,
            {
                mode: cors,
                credentials: 'include'
            }
        ).then(res => res.json());
    }

    allowObjectives(id_) {
        return fetch(
            IP + '/objectives/allow/' + id_,
            {
                mode: cors,
                credentials: 'include'
            }
        ).then(res => res.json());
    }

    coachGetObjectives() {
        return fetch(
            IP + '/objectives/coachget',
            {
                mode: cors,
                credentials: 'include'
            }
        ).then(res => res.json());
    }

    acceptObjective(id_) {
        return fetch(
            IP + '/objectives/accept/' + id_,
            {
                mode: cors,
                credentials: 'include'
            }
        ).then(res => res.json());
    }
}

