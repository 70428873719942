<template>
  <div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <img alt="Logo" :src="topbarImage()" />
      <span></span>
    </router-link>
    <div v-if="!isSignUp">
      <button
        class="p-link layout-menu-button layout-topbar-button"
        @click="onMenuToggle"
      >
        <i class="pi pi-bars"></i>
      </button>
    </div>

    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true,
      }"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>
    <ul ref="secondmenu" class="layout-topbar-menu hidden lg:flex origin-top">
      <li>
        <Button v-if="total_tokens != -1" icon="pi pi-dollar" :label="total_tokens" class="p-button-rounded p-button-text" @click="gotoTokens">
            {{total_tokens}} Ludi
        </Button>
        <!--<button class="p-link layout-topbar-button" v-on:click="gotoTokens">
            Tokens
          <i class="pi pi-dollar"></i>
          <span>{{ total_tokens }} test</span>
        </button>-->
      </li>
      <li>
        <button class="p-link layout-topbar-button" v-on:click="gotoSettings">
          <i class="pi pi-cog"></i>
          <span>Settings</span>
        </button>
      </li>
      <li>
        <button class="p-link layout-topbar-button" v-on:click="gotoProfile">
          <i class="pi pi-user"></i>
          <span>Profile</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import UserService from './service/UserService';

export default {
  data() {
    return {
      total_tokens: -1,
    }
  },
  userService: null,
  created() {
    this.userService = new UserService();
  },
  mounted() {
    this.userService.getXLM().then(data => {
        this.total_tokens = 0;
        if ("error" in data) {
            this.total_tokens = -1;
        } else {
            for (var idx in data) {
                this.total_tokens += data[idx]["amount"];
            }
        }
    });
  },

  methods: {
    /* isSignUp() {
            return true;
            console.log(this.$route.name);
            if (this.$route.name && typeof(this.$route.name) !== "undefined" && this.$route.name !== null) {
                console.log(this.$route.name);
                return this.$route.name.startsWith('signup') || this.$route.name.startsWith('login');
            }
            console.log("AAA");
            return false;
        },*/
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    onTopbarMenuToggle(event) {
      this.$emit("topbar-menu-toggle", event);
      this.$refs.secondmenu.classList.add("hidden");
    },
    topbarImage() {
      return "images/coachera-logo.png";
    },
    gotoProfile(event) {
      this.$router.push("/profile");
      this.onTopbarMenuToggle(event);
    },
    gotoSettings(event) {
      this.$router.push("/settings");
      this.onTopbarMenuToggle(event);
    },
    gotoTokens(event) {
      this.$router.push("/tokens");
      this.onTopbarMenuToggle(event);
    },
  },
  computed: {
    isSignUp() {
      if (
        this.$route.name &&
        typeof this.$route.name !== "undefined" &&
        this.$route.name !== null
      ) {
        return (
          this.$route.name.startsWith("signup") ||
          this.$route.name.startsWith("login") ||
          this.$route.name.startsWith("forgotpassword")
        );
      }
      return false;
    },
  },
};
</script>
